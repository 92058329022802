<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Consumption Date</th>
          <th>Consumption No</th>
          <th>Transfer From</th>
          <th>Transfer To</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <td>14/3/22</td>
        <td>#C4545</td>
        <td>A</td>
        <td>B</td>
        <td>Active</td>
        <td>View/Edit</td>
        </tbody>
      </table>
      <!-- <p v-if="!journals.length" class="text-center mt-5">No data</p> -->
    </div>
  </div>
</template>

<script setup></script>
